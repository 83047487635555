import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, CardDeck, Card, Alert } from 'react-bootstrap'
import Img from "gatsby-image"
import { FaCheck } from "react-icons/fa"
import ReactMarkdown from 'react-markdown'

class BirthdaysPage extends React.Component {
    constructor (props) {
        super(props)

        const { mdx } = props.data
        const { frontmatter } = mdx

        this.state = {
            ...frontmatter
        }
    }

    componentDidMount() {}

    render() {
        return (
            <Layout>
                <SEO title="Organisation d'anniversaires" />
              
              <Container className="py-4 text-justify">
      
                  <div id ="birthdays">
                      <Img fluid={this.state.birthday_image.childImageSharp.fluid} alt="anniversaires" className="mx-auto d-block w-75" />
                      
                      <div className="mt-3">
                      <Alert className="alert-green">
                      <ReactMarkdown parserOptions={{ commonmark: true }} source={this.state.description} />
                      </Alert>
      
                      <CardDeck className="mb-3 mt-4 text-center">
                          {
                              this.state.packs.map((pack, index) => {
                                  return (
                                      <Card key={"card_"+index}>
                                          <Card.Header className="bg-grey h4 text-green-dark text-center">Anniversaire clés-en-main</Card.Header>
                                          <Card.Body>
                                          <Card.Title className="h2 mb-2">
                                              <h3>{pack.price}€/après-midi</h3>
                                              </Card.Title>
                                              <ul className="text-left list-unstyled mt-3 mb-4 mx-3">
                                                  {
                                                      pack.features.map((feature, index) => {
                                                          return <li key={'feature_' + index}><FaCheck className="mr-2" />{feature}</li>
                                                      })
                                                  }
                                              </ul>
                                          </Card.Body>
                                      </Card>
                                  )
                              })
                          }
                      </CardDeck>
      
                      <Alert className="alert-green text-center">
                          Pour plus d'informations sur les disponibilités et l'organisation, n'hésitez pas à me contacter : <a href="mailto:contact@lesenfantsdelarbrevert.fr">contact@lesenfantsdelarbrevert.fr</a>
                      </Alert>
      
                      </div>
      
                  </div>
              </Container>
            </Layout>
          )
        }
}

export default BirthdaysPage

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                image_hero {
                    childImageSharp {
                        fluid(maxWidth: 1024, quality: 75) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
               
                description
                birthday_image {
                    childImageSharp {
                        fluid(maxWidth: 512, quality: 75) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                packs {
                    features
                    price
                }
            }
        }
    }`
